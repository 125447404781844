.tiptap {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 10px;
    height: 35vh;
    width: 100%;
    > * + * {
        margin-top: 0.75em;
    }

    input[type="file"]::file-selector-button {
        border: 2px solid #6c5ce7;
        padding: 0.2em 0.4em;
        border-radius: 0.2em;
        background-color: #a29bfe;
        transition: 1s;
    }

    input[type="file"]::file-selector-button:hover {
        background-color: #81ecec;
        border: 2px solid #00cec9;
    }
    ul,
    ol {
        padding: 0 1rem;
    }
    p {
        margin: 5px 0;
        font-family: "gordita_regular" !important;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
        margin: 5px 0;
        font-family: "gordita_medium" !important;
        color: #fff;
    }

    code {
        background-color: rgba(#616161, 0.1);
        color: #616161;
        margin: 5px 0;
    }

    pre {
        background: #0d0d0d;
        border-radius: 0.5rem;
        color: #fff;
        font-family: "JetBrainsMono", monospace;
        padding: 0.75rem 1rem;
        margin: 5px 0;

        code {
            background: none;
            color: inherit;
            font-size: 0.8rem;
            padding: 0;
            margin: 5px 0;
        }

        .hljs-comment,
        .hljs-quote {
            color: #616161;
        }

        .hljs-variable,
        .hljs-template-variable,
        .hljs-attribute,
        .hljs-tag,
        .hljs-name,
        .hljs-regexp,
        .hljs-link,
        .hljs-name,
        .hljs-selector-id,
        .hljs-selector-class {
            color: #f98181;
        }

        .hljs-number,
        .hljs-meta,
        .hljs-built_in,
        .hljs-builtin-name,
        .hljs-literal,
        .hljs-type,
        .hljs-params {
            color: #fbbc88;
        }

        .hljs-string,
        .hljs-symbol,
        .hljs-bullet {
            color: #b9f18d;
        }

        .hljs-title,
        .hljs-section {
            color: #faf594;
        }

        .hljs-keyword,
        .hljs-selector-tag {
            color: #70cff8;
        }

        .hljs-emphasis {
            font-style: italic;
        }

        .hljs-strong {
            font-weight: 700;
        }
    }
    img {
        max-width: 100%;
        height: auto;
    }

    blockquote {
        padding-left: 1rem;
        border-left: 3px solid rgba(0, 0, 0, 0.5);
    }

    hr {
        border: none;
        border-top: 2px solid rgba(rgb(13, 13, 13), 0.1);
        margin: 2rem 0;
    }
    a {
        color: #117acd; /* Maintains white text for contrast */
        text-decoration: underline; /* No underline */
        cursor: pointer; /* Cursor changes to pointer to indicate clickability */
        transition: background-color 0.3s ease; /* Smooth transition for hover effect */
    }
    .file-link {
        color: #737373;
        display: inline-flex;
        align-items: center;
        background: #7d7d7d3d;
        width: max-content;
        padding: 4px 8px;
        border-radius: 5px;
        font-family: "gordita_regular";
        margin: 0 5px;
    }
    .file-link::before {
        content: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/16-02-2024/icons/document.svg"); /* with class ModalCarrot ??*/
        height: 22px;
    }
    .is-editor-empty:first-child::before {
        color: #adb5bd;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
        margin-top: 0px;
    }
}
p:has(> a) {
    display: inline-block; /* or any other display value you want */
}
/* Select */
.custom-select-wrapper {
    position: relative;
    display: inline-block;
}

.custom-select-wrapper:after {
    /* content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #333; /* Customize the color to match your design */
    /* pointer-events: none;  */
    display: none;
}

.custom-select {
    appearance: none; /* Remove default select styling */
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px 10px;
    padding-right: 30px; /* Make space for custom icon */
    border-radius: 5px;
    font-size: 16px;
}
.is-editor-empty {
    margin: 0px;
}
/* Optionally, style the select:focus for better UX */
.custom-select:focus {
    outline: none;
    border-color: #009578; /* Example focus color */
}
div.image-selector {
    display: flex;
}
button.icon {
    background: transparent;
    border: none;
    padding: 3px;
}
button.is-active {
    border: none;
    color: #000;
    background: #90909070;
    border-radius: 4px;
    padding: 3px;
}
.ProseMirror {
    overflow: auto;
    aspect-ratio: 16/9;
    background: #2c2c2c;
}
.ProseMirror:focus {
    outline: none;
}
.menu {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    background: #1b1c1f;
    /* justify-content: space-between; */
    padding: 10px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
div.text-style {
    display: flex;
    gap: 5px;
    align-items: center;
}
div.text-style button {
    margin: 0;
    padding: 5px;
    display: flex;
    cursor: pointer;
}
div.list-style {
    display: flex;
    gap: 5px;
    align-items: center;
}
div.list-style button {
    margin: 0;
    padding: 5px;
    display: flex;
    cursor: pointer;
}
div.btn-undo {
    display: flex;
    gap: 5px;
    align-items: center;
}
div.btn-undo button {
    margin: 0;
    padding: 5px;
    display: flex;
    background: none;
    border: none;
    cursor: pointer;
}
.menu button {
    font-family: "gordita_medium";
    width: max-content;
}
.btn-upload {
    cursor: pointer;
    color: white;
    padding: 5px;
    display: flex;
    border-radius: 4px;
}
